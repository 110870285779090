import {AppState} from '../states/app.state';
import {createSelector} from '@ngrx/store';
import {firmenEntitiesAdapter} from '../adapters/firmen-entities.adapters';
import {identity} from 'rxjs';


export class FirmenEntitiesSelectors {
  private static adapterSelectors = firmenEntitiesAdapter.getSelectors();

  private static select = {
    entities: (state: AppState) => state.firmen,
    isLoaded: (state: AppState) => state.firmen.firmenLoaded,
  };

  public static all = createSelector(
    this.select.entities,
    this.adapterSelectors.selectAll,
  );

  public static firmenLoaded = createSelector(
    this.select.isLoaded,
    identity,
  );
}
