import {createAction, props} from '@ngrx/store';
import {FirmaExtendedReadResponseDTO} from '../../openapi/partner-openapi';
import {MappedHttpErrorResponse} from '@adnova/jf-ng-components';


export class FirmenEntitiesActions {

  /**
   * @action readFirmen
   * @description
   * Diese Aktion wird verwendet, um alle Betriebe zu laden.
   */
  public static readFirmen = createAction(
    '[Overview] Read firmen.',
  );

  public static readFirmenSuccess = createAction(
    '[Betriebe Effect] Read firmen successfully.',
    props<{
      firmenDtos: FirmaExtendedReadResponseDTO[],
    }>()
  );

  public static readFirmenFailure = createAction(
    '[Betriebe Effect] Read firmen failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>()
  );
}
