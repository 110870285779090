import {ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';
import {environment} from '../../../environments/environment';
import {AppState} from '../states/app.state';
import {initialJfLibState, jfLibReducers} from '@adnova/jf-ng-components';
import {firmenEntitiesReducer, initialFirmenEntitiesState} from './firmen-entities.reducers';
import {routerReducer} from '@ngrx/router-store';


export const initialAppState: AppState = {
  ...initialJfLibState,
  firmen: initialFirmenEntitiesState,
};

export const reducers: ActionReducerMap<AppState> = {
  ...jfLibReducers,
  firmen: firmenEntitiesReducer,
  router: routerReducer,
};

export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [logger] : [];
