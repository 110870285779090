import {createReducer, on} from '@ngrx/store';
import {firmenEntitiesAdapter} from '../adapters/firmen-entities.adapters';
import {FirmenEntitiesState} from '../states/firmen-entities.state';
import {FirmenEntitiesActions} from '../actions/firmen-entities.actions';


export const initialFirmenEntitiesState: FirmenEntitiesState = firmenEntitiesAdapter.getInitialState(
  {
    firmenLoaded: false,
  }
);

export const firmenEntitiesReducer = createReducer(
  initialFirmenEntitiesState,

  on(FirmenEntitiesActions.readFirmenSuccess, (state, {firmenDtos: responseDtos}) => {
    return firmenEntitiesAdapter.upsertMany(
      responseDtos,
      {
        ...state,
        firmenLoaded: true
      });
  }),
);
