import {Actions, createEffect, ofType} from '@ngrx/effects';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NGXLogger} from 'ngx-logger';
import {Injectable} from '@angular/core';
import {FirmenService} from '../../openapi/partner-openapi';
import {FirmenEntitiesActions} from '../actions/firmen-entities.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {mappedHttpErrorResponseOperator} from '@adnova/jf-ng-components';


@Injectable()
export class FirmenEntitiesEffects {

  constructor(
    private actions$: Actions,
    private firmenService: FirmenService,
    private logger: NGXLogger,
    private snackbar: MatSnackBar,
  ) {
  }

  /**
   * Lädt alle Firmen des Akteurs vom Service.
   */
  readonly readFirmen$ = createEffect(
    () => this.actions$.pipe(
      ofType(FirmenEntitiesActions.readFirmen),
      switchMap(() => {
        return this.firmenService.readFirmenExtendedList().pipe(
          map(firmenDtos => {

            this.logger.debug(
              'read firmen succeeded',
            );

            return FirmenEntitiesActions.readFirmenSuccess({
              firmenDtos,
            });
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(() => {
              this.logger.error(
                'read firmen failed. error: ',
                error,
              );

              return FirmenEntitiesActions.readFirmenFailure({
                error: error,
              });
            }),
          ))
        );
      }),
    ),
  );

  /**
   * Error-Handling für das Laden von Firmen.
   */
  readonly readFirmenFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(FirmenEntitiesActions.readFirmenFailure),
      map(({
             error,
           }) => {

        let errorMsg = '';

        switch (error.status) {
          case 403: {
            errorMsg = 'Fehlende Berechtigungen für das Laden der Betriebe. ' +
              'Bitte Kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Laden der Betriebe. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
          });
      }),
    ), {dispatch: false}
  );
}
