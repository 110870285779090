import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './auth/auth.guard';
import {AppNavigationComponent} from './modules/pages/app-navigation/app-navigation.component';
import {NavigationLink} from './modules/pages/app-navigation/navigation-link.interface';
import {FirmenResolver} from './resolvers/firmen-resolver.service';


/** INFO:
 * Die BetriebsauswahlPage muss über der Ebene der Routes liegen,
 * in der Sie eingebunden sein soll, um nicht bei jedem Navigationsschritt neu geladen zu werden.
 *
 */

const routes: Routes = [
  {
    path: '',
    component: AppNavigationComponent,
    // TODO AuthGuard entfernen
    canActivate: [AuthGuard],
    data: {
      navigationLinks: [ // INFO: Navigationselemente für die App-Navigation oberhalb des Contents.
        {
          label: 'Übersicht',
          route: 'overview',
        },
      ] as NavigationLink[],
    },
    children: [
      {
        path: '',
        redirectTo: 'overview',
        pathMatch: 'full',
      },
      {
        path: 'overview',
        data: {
          redirectAllowed: true, // INFO: Deaktivieren der automatischen Betriebssynchronisation.
          // TODO buttonRoute: '', // INFO: Route des Zur-Übersicht-Buttons. Das Fragment ":inhaber" wird durch die aktuelle Inhaber-ID ersetzt.
        },
        resolve: [
          FirmenResolver,
        ],
        loadComponent: () => import('./modules/routes/overview/overview.component').then(m => m.OverviewComponent),
      },
      {
        path: 'edit/inhaber/:inhaber',
        loadComponent: () => import('./modules/routes/firma-edit/firma-edit.component').then(m => m.FirmaEditComponent),
        data: {
          buttonLabel: 'Zur Übersicht', // INFO: Zur-Übersicht-Button anzeigen.
          buttonRoute: 'overview', // INFO: Route des Zur-Übersicht-Buttons. Das Fragment ":inhaber" wird durch die aktuelle Inhaber-ID ersetzt.
          buttonIcon: 'arrowLeft', // INFO: Icon für den Zur-Übersicht-Button.
          buttonVariant: 'ghost' // INFO: Variante für den Zur-Übersicht-Button.
        }
      },
      {
        path: 'edit/fakturierung/inhaber/:inhaber',
        loadComponent: () => import('./modules/routes/firma-edit-fakturierung/firma-edit-fakturierung.component').then(m => m.FirmaEditFakturierungComponent),
        data: {
          buttonLabel: 'Zur Übersicht', // INFO: Zur-Übersicht-Button anzeigen.
          buttonRoute: 'overview', // INFO: Route des Zur-Übersicht-Buttons. Das Fragment ":inhaber" wird durch die aktuelle Inhaber-ID ersetzt.
          buttonIcon: 'arrowLeft', // INFO: Icon für den Zur-Übersicht-Button.
          buttonVariant: 'ghost' // INFO: Variante für den Zur-Übersicht-Button.
        }
      },
      {
        path: 'legal/impressum',
        data: {
          redirectAllowed: false, // INFO: Deaktivieren der automatischen Betriebssynchronisation.
          navigationLinks: [] as NavigationLink[], // INFO: Oben keine Navigationselemente anzeigen.
          buttonLabel: 'Zur Übersicht', // INFO: Zur-Übersicht-Button anzeigen.
          buttonRoute: 'overview', // INFO: Route des Zur-Übersicht-Buttons. Das Fragment ":inhaber" wird durch die aktuelle Inhaber-ID ersetzt.
          buttonIcon: 'arrowLeft', // INFO: Icon für den Zur-Übersicht-Button.
          buttonVariant: 'ghost' // INFO: Variante für den Zur-Übersicht-Button.
        },
        loadComponent: () => import('./modules/routes/legal/impressum/impressum.component').then(m => m.ImpressumComponent),
      },
    ]
  },
  {
    path: '**',
    redirectTo: 'overview',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'top',
      preloadingStrategy: PreloadAllModules
    }
  ),],
  providers: [],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
