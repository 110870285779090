import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {AppState} from '../store/states/app.state';
import {FirmenEntitiesSelectors} from '../store/selectors/firmen-entities.selectors';
import {FirmenEntitiesActions} from '../store/actions/firmen-entities.actions';


@Injectable({
  providedIn: 'root'
})
export class FirmenResolver {

  constructor(
    private store: Store<AppState>,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    this.store.select(FirmenEntitiesSelectors.firmenLoaded).subscribe(loaded => {
      if (!loaded) {
        this.store.dispatch(FirmenEntitiesActions.readFirmen());
      }
    });
  }
}
